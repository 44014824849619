































































































import { TaskForm } from "@/types/taskForm";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VAutocompleteFilter from "@/components/utility/VAutocompleteFilter.vue";
import { TaskType } from "@/types/helpers";
import taskModule from "@/store/modules/tasks";
import SubjectDetails from "@/components/subject/SubjectDetails.vue";
import Factory from "@/types/Factory";
import SimpleDatePicker from "@/components/utility/simpledatepicker.vue";
import VMarkdownEditor from "@/components/utility/markdown.vue";
import moment from "moment";
import { Task } from "@/types/task";
import SelectType from "@/components/inputs/SelectType.vue";
import SelectSubject from "@/components/inputs/SelectSubject.vue";

@Component({
  components: {
    VAutocompleteFilter,
    SubjectDetails,
    SimpleDatePicker,
    VMarkdownEditor,
    SelectType,
    SelectSubject,
  },
})
export default class TaskDetails extends Vue {
  @Prop({
    default: () => Factory.getTaskForm(),
  })
  data!: TaskForm;
  isLoading = false;

  errors = {};
  task = this.data;

  get isNewTask(): boolean {
    return this.task.id == -1;
  }

  get dueAt(): string {
    return this.task.due_at;
  }

  set dueAt(value: string) {
    const due = moment(value, "YYYY-MM-DD");
    const start = moment(this.task.start_at, "YYYY-MM-DD");
    this.task.due_at = value;
    if (start.isAfter(due)) this.task.start_at = value;
  }

  mounted(): void {
    this.task = Object.assign({}, this.task, this.$route.query);
  }

  get showStartAt(): boolean {
    return this.task.tasktype_id == TaskType.PROJECT.toString();
  }

  @Watch("data")
  onDataChange(val: TaskForm): void {
    this.task = val;
  }

  async save(): Promise<void> {
    this.isLoading = true;
    try {
      const taskForm: Task = JSON.parse(JSON.stringify(this.task));
      const task = await taskModule.save(taskForm);
      this.$toast.success(this.$t("global.success").toString());
      this.errors = {};
      this.$router.push({ name: "taskDisplay", params: { task_id: task.id } });
    } catch (err) {
      this.errors = err.response.data.errors;
      this.$toast.error(this.$t("global.error-form").toString());
    } finally {
      this.isLoading = false;
    }
  }
}
