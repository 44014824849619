


























import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SimpleDatePicker extends Vue {
  @Prop({ default: null }) max!: string | null;
  @Prop({ default: moment().toISOString().substr(0, 10) }) value!: string;
  menu = false;

  get toDayDate(): string {
    return moment().toISOString().substr(0, 10);
  }

  get date(): string {
    return this.value;
  }

  set date(value: string) {
    this.$emit("input", value);
  }
}
